import {Route, Switch} from "react-router-dom";
import React, {useContext, useState, useEffect} from "react";
import {FirebaseContext} from "../Firebase";
import {pdf} from "@react-pdf/renderer";
import {Document as RP_Document, Page as RP_Page, pdfjs} from 'react-pdf'
// import logo from './ULLogos/Mark_E329969_EN.png'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
// import BockFixture from "./LabelDesigns/BockFixture";
import BockBox from "./LabelDesigns/BockBox";
import LSIBox from "./LabelDesigns/LSIBox";
import './labelPreview.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const AddPrinter = () => {

    return (
        <>
            <h1>
                Add Printer
            </h1>
        </>
    )
}


// const MyDocument = () => (
//
// );


const Labels = ({ labelDetails }) => {

    const {getFunction} = useContext(FirebaseContext)

    const printLabel = getFunction('printLabel')

    const [pdfURL, setPDFURL] = useState('')


    useEffect(() => {
        // Determine which label to use based on labelDetails type
        const labelComponent = labelDetails?.type === 'LSI' ? 
            <LSIBox labelDetails={labelDetails}/> : 
            <BockBox labelDetails={labelDetails}/>;
            
        pdf(labelComponent).toBlob().then(r => {
            console.log(r.toString('base64'))
            const uRl = URL.createObjectURL(r)
            const pdf2base64 = require('pdf-to-base64');
            pdf2base64(uRl)
                .then(
                    (response) => {
                        printLabel({labelPDF: response})
                        console.log(response); //cGF0aC90by9maWxlLmpwZw==
                    }
                )
                .catch(
                    (error) => {
                        console.log(error); //Exepection error....
                    }
                )

            setPDFURL(uRl)
        })
    }, [])


    return (<>
        <h1>Labels</h1>
        <ul>
            <li>Add Printer</li>
            <li>List Printers</li>
        </ul>
        <hr/>
        {pdfURL.length && <div style={{width: '400px'}}>
            {/*<PdfViewer url={pdfURL}/>*/}
            <RP_Document
                file={pdfURL}
                // style={{width:'200px'}}
                renderMode={'svg'}
                // externalLinkTarget={'_self'}
            >
                <RP_Page pageNumber={1} width={'398'}/>
            </RP_Document>
        </div>}
        {/*<PDF />*/}
        <hr/>
        <Switch>
            <Route exact path={'/labels/addPrinter'} component={AddPrinter}/>
        </Switch>
    </>)
}

export default Labels
